import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import content from "../../content/hero.yaml"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

export default () => {
  const data = useStaticQuery(graphql`
  query HeroImageQuery {
    file(relativePath: {eq: "business-meeting.png"}) {
      childImageSharp {
        fluid {
          aspectRatio
          base64
          sizes
          src
          srcSet
        }
      }
    }
  }
  `) 
  return (

  <section id="hero">
    <div className="row">
      <div className="twelve columns">

        <div className="hero-text">
          <h1 className="responsive-headline">{ content.headline }</h1>
          <p>{ content.body }</p>
        </div>

        <div className="buttons">
          <AnchorLink className="button trial animated shake" to={ content.button1.to }>{ content.button1.label }</AnchorLink>
          <AnchorLink className="button learn-more" to={ content.button2.to }>{ content.button2.label }</AnchorLink>
        </div>

        <div className="hero-image">
          {/* <img src={ content.image } alt="" className="animated fadeInUpBig" /> */}
          <Img fluid={data.file.childImageSharp.fluid} alt="Business meeting with Standup Dev" />
        </div>
      </div>
    </div>
  </section>
) }
